Vue.component("manufacturer-image-carousel", {

    template: `<div :id="'owl-carousel-' + _uid" v-if="manufacturers.length > 0" class="owl-carousel owl-carousel-manufacturer owl-theme">
        <div class="item" v-for="(manufacturer, index) in manufacturers">
            <a v-if="manufacturer.url" :href="manufacturer.url">
                <img class="owl-lazy" :data-src="manufacturer.logo" :alt="manufacturer.name">
            </a>
            <img v-else class="owl-lazy" :data-src="manufacturer.logo" :alt="manufacturer.name">
        </div>
    </div>`,

    props: {
        manufacturersData: {
            type: Array
        },
        responsiveOne: {
            type: Number
        },
        responsiveTwo: {
            type: Number
        },
        responsiveThree: {
            type: Number
        },
        enableCarousel: {
            type: Boolean
        },
        template: {
            type: String
        }
    },

    data()
    {
        return {
            $_enableCarousel: false
        };
    },

    computed: {
        manufacturers()
        {
            return this.manufacturersData;
        }
    },

    created()
    {
        this.$_enableCarousel = this.enableCarousel && this.manufacturers.length > 0;
    },

    mounted()
    {
        this.$nextTick(() =>
        {
            if (this.$_enableCarousel)
            {
                this.initializeCarousel();
            }
        });
    },

    methods: {
        initializeCarousel()
        {
            $("#owl-carousel-" + this._uid).owlCarousel({
                dots: false,
                loop: this.manufacturers.length >= this.responsiveThree,
                lazyLoad: true,
                margin: 10,
                nav: true,
                navText: [
                    `<i id="owl-nav-text-left-${this._uid}" class='fa fa-chevron-left' aria-hidden='true'></i>`,
                    `<i id="owl-nav-text-right-${this._uid}" class='fa fa-chevron-right' aria-hidden='true'></i>`
                ],
                responsiveClass: true,
                responsive: {
                    0: {
                        items: this.manufacturers.length >= this.responsiveOne ? this.responsiveOne : this.manufacturers.length
                    },
                    600: {
                        items: this.manufacturers.length >= this.responsiveTwo ? this.responsiveTwo : this.manufacturers.length
                    },
                    1000: {
                        items: this.manufacturers.length >= this.responsiveThree ? this.responsiveThree : this.manufacturers.length
                    }
                },
                onInitialized: event =>
                {
                    document.querySelector(`#owl-nav-text-left-${this._uid}`).parentElement.onclick = event => event.preventDefault();
                    document.querySelector(`#owl-nav-text-right-${this._uid}`).parentElement.onclick = event => event.preventDefault();
                }
            });
        }
    }
});
