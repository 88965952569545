import CategoryImageCarousel from "./CategoryImageCarousel.js";
import ItemStoreSpecial from "./ItemStoreSpecial.js";

Vue.component("category-item", {
    components: {
        CategoryImageCarousel,
        ItemStoreSpecial
    },

    props: {
        decimalCount:
        {
            type: Number,
            default: 0
        },
        imageUrlAccessor:
        {
            type: String,
            default: "urlMiddle"
        },
        itemData:
        {
            type: Object
        },
        disableCarouselOnMobile:
        {
            type: Boolean
        },
        paddingClasses:
        {
            type: String,
            default: null
        },
        paddingInlineStyles:
        {
            type: String,
            default: null
        }
    },

    jsonDataFields: [
        "itemDataRef"
    ],

    computed:
    {
        item()
        {
            let itemData = (this.itemData || this.itemDataRef);
            const beforePricesFree = $(this.$refs.beforePrices).find(".free-fields-storage");
            const beforePricesAvailability = $(this.$refs.beforePrices).find(".availability-id-storage");

            if (beforePricesFree.length > 0)
            {
                const freeFields = beforePricesFree.data("free");

                Object.keys(freeFields).map(function(key, index)
                {
                    if (!freeFields[key] || freeFields[key] == "0")
                    {
                        freeFields[key] = "";
                    }
                });
                itemData = { ...itemData, ...{ freeFields: freeFields } };
            }

            if (beforePricesAvailability.length > 0)
            {
                const availability = beforePricesAvailability.data("availability");

                itemData = { ...itemData, ...{ availabId: availability } };
            }

            return itemData;
        },

        /**
         * returns itemData.item.storeSpecial
         */
        storeSpecial()
        {
            return this.item.item.storeSpecial;
        },

        /**
         * returns itemData.texts
         */
        texts()
        {
            return this.item.texts;
        },

        itemPrice()
        {
            return this.$options.filters.specialOffer(this.item.prices.default.unitPrice.formatted, this.item.prices, "unitPrice", "formatted" );
        },

        itemSetPrice()
        {
            return this.$options.filters.currency(
                this.item.prices.default.price.value,
                this.item.prices.default.currency
            );
        },

        urlWithVariationId()
        {
            return !this.$ceres.config.item.showPleaseSelect || this.$ceres.initialPleaseSelect == 0;
        },

        ...window.Vuex.mapState({
            showNetPrices: state => state.basket.showNetPrices
        })
    }
});
