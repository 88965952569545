Vue.filter("unitCustomFormat", function(unit)
{
    let newUnit = unit.trim();

    if (newUnit == "Milligramm")
    {
        newUnit = "mg";
    }
    if (newUnit == "Gramm")
    {
        newUnit = "g";
    }
    if (newUnit == "Kilogramm")
    {
        newUnit = "kg";
    }
    if (newUnit == "Liter")
    {
        newUnit = "l";
    }
    if (newUnit == "Milliliter")
    {
        newUnit = "ml";
    }
    if (newUnit == "Zentimeter")
    {
        newUnit = "cm";
    }
    if (newUnit == "Meter")
    {
        newUnit = "m";
    }
    if (newUnit == "Quadratmeter")
    {
        newUnit = "m<sup>2</sup>";
    }
    if (newUnit == "Quadratzentimeter")
    {
        newUnit = "m<sup>2</sup>";
        // "cm<sup>2</sup>";
    }
    if (newUnit == "Quadratmillimeter")
    {
        newUnit = "mm<sup>2</sup>";
    }

    return newUnit;
});

Vue.filter("trimUnit", function(basePrice)
{
    return basePrice.split("/")[0];
});

Vue.filter("trimUnitPrice", function(basePrice)
{
    return basePrice.split("/").pop().trim();
});

Vue.filter("getFacetNameById", function(id, facets)
{
    const facet = facets.find(item => item.id === id);

    return (facet && facet.name) ? facet.name : id;
});

Vue.filter("getRealBasketItem", function(basketItem, basketItems)
{
    const realBasketItem = basketItems.find(item => item.id === basketItem.id);

    return realBasketItem ? realBasketItem : basketItem;
});


function lfAddToBasket(variationId, qty)
{
    window.ceresStore.dispatch("loadComponent", "add-item-to-basket-overlay");
    window.ceresStore.dispatch("loadComponent", "basket-preview");

    const basketObject = {
        variationId: variationId,
        quantity: qty
    };

    window.ceresStore.dispatch("addBasketItem", basketObject).then(
        response =>
        {
            document.dispatchEvent(new CustomEvent("afterBasketItemAdded", {
                detail: basketObject
            }));
            window.ceresStore.commit("setIsAddToBasketLoading", 0);
        },
        error =>
        {
            window.ceresStore.commit("setIsAddToBasketLoading", 0);
            if (error.data)
            {
                window.CeresNotification.error("Failed add to basket.").closeAfter(2000);
            }
        });
}

function stripTrailingSlash(str)
{
    if (str.substr(-1) === "/")
    {
        return str.substr(0, str.length - 1);
    }
    return str;
}

$(function()
{
    if (!App.isShopBuilder)
    {
        if ($("#mainTopMenuWrapper").attr("data-sticky") === "true")
        {
            // Check the initial Poistion of the Sticky Header
            const stickyHeaderTop = $("#mainTopMenuWrapper").offset().top;

            $(window).scroll(function()
            {
                if ($(window).scrollTop() > stickyHeaderTop && $("#mainTopMenuWrapper").is(":visible"))
                {
                    $("#mainTopMenuWrapper").addClass("sticky");
                    $("body").addClass("sticky-menu");
                }
                else
                {
                    $("body").removeClass("sticky-menu");
                    $("#mainTopMenuWrapper").removeClass("sticky");
                    window.dispatchEvent(new Event("resize"));
                }
            });
        }
    }

    $("body").on("click", "[data-pl-collapse]", function()
    {
        const id = $(this).attr("data-pl-collapse");

        if (id)
        {
            const collapseBlock = $("[data-pl-cl=\"" + id + "\"]");

            if (collapseBlock.length)
            {
                if (collapseBlock.is(":hidden"))
                {
                    $(this).addClass("visible");
                }
                else
                {
                    $(this).removeClass("visible");
                }
                collapseBlock.slideToggle("slow");
            }
        }
        return false;
    });

    /* SingleItem */
    $("body").on("click", ".single-carousel-container .toggle-thumbs", function()
    {
        $(this).closest(".single-carousel-container").toggleClass("hover");
    });

    $("body").on("click", ".extend-attributes", function()
    {
        const parent = $(this).parent().parent();

        if (parent.hasClass("expanded"))
        {
            parent.removeClass("expanded");
            $(this).find(".text").text("alle Maße anzeigen und auswählen");
        }
        else
        {
            parent.addClass("expanded");
            $(this).find(".text").text("Maße zuklappen");
        }
    });

    $("body").on("click", ".sample-link", function()
    {
        const variationId = $(this).attr("data-variation");

        if (variationId)
        {
            lfAddToBasket(variationId, 1);
        }
    });

    $("body").on("change", ".order-property-slider .form-check-input[type=\"checkbox\"]", function()
    {
        const group = $(this).parent().parent().parent().parent();

        if ($(this).is(":checked"))
        {
            const val = $(this).val();

            group.find(".form-check-input[type=\"checkbox\"]").each(function()
            {
                if ($(this).val() != val)
                {
                    $(this).prop("checked", false);
                    $(this)[0].dispatchEvent(new Event("change"));
                }
            });
        }
        else
        {
            if (group.find(".form-check-input[type=\"checkbox\"]:checked").length == 0)
            {
                const elem = group.find(".form-check-input[type=\"checkbox\"]").first();

                elem.prop("checked", true);
                elem[0].dispatchEvent(new Event("change"));
            }
        }
    });

    const inerval1 = setInterval(function()
    {
        if ($(".order-property-slider .form-check-input[type=\"checkbox\"]").length > 0)
        {
            $(".order-property-slider .form-check-input[type=\"checkbox\"]").each(function()
            {
                $(this)[0].dispatchEvent(new Event("change"));
                $(this).trigger("change");
            });
            clearInterval(inerval1);
        }
    }, 100);

    const inerval2 = setInterval(function()
    {
        const widget = jQuery(".ekomi-clickable-single .stars-widget").clone();

        if (widget.length && !widget.hasClass("moved"))
        {
            const link = jQuery(".widget-tab .nav .nav-item .nav-link:contains(\"Bewertungen\")");

            if (link.length)
            {
                widget.addClass("moved");
                //widget.appendTo(link);
                link.append(widget);
                clearInterval(inerval2);
            }
        }
    }, 500); 

    $("#singleItemInfo").on("shown.bs.modal", function()
    {
        const iframe = $(this).find("iframe");

        if (!iframe.attr("src"))
        {
            iframe.attr("src", iframe.attr("data-src"));
        }
    });

    /* Category */
    $("body").on("click", ".cmp-type2-trigger", function()
    {
        $("body").addClass("cmp-type2");
        return false;
    });
    $("body").on("click", ".cmp-type-trigger", function()
    {
        $("body").removeClass("cmp-type2");
        return false;
    });

    /* Filters */
    $("body").on("click", ".findologic-filter-wrapper .card > .h3", function()
    {
        $(this).toggleClass("hidden-state");
        return false;
    });

    $("body").on("click", ".findologic-filter-wrapper .toggle-limited-options", function()
    {
        const limited = $(this).closest(".card");

        limited.toggleClass("limit-visible");
    });
    $(document).on('click','.ekomi-clickable-single',function(){
        $('.nav-link .stars-widget').closest('.nav-link')[0].click()
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".widget-tab").offset().top - $('#mainTopMenuWrapper').height() - 10
        }, 500);
    });
    $("body").on("click", ".toggle-mobile-filters", function()
    {
        $("body").toggleClass("modal-open");
        $(".widget-navigation-tree.mobmenu").toggleClass("visible");
    });

    /* Contact */
    if ($(".widget-text-input.frage-zu-artikel-inject input[type=\"text\"]").length)
    {
        const urlParams = new URLSearchParams(window.location.search);
        const fragezuartikelParam = urlParams.get("fragezuartikel");

        if (fragezuartikelParam)
        {
            $(".widget-text-input.frage-zu-artikel-inject input[type=\"text\"]").val(fragezuartikelParam);
        }
    }

    /* CMS Page */
    $(".widget-navigation-collapse-tree .collapse-category a").each(function()
    {
        if (stripTrailingSlash($(this).attr("href")) == stripTrailingSlash(window.location.pathname))
        {
            $(this).addClass("active");
            $(this).closest(".collapse-category").addClass("active");
        }
    });

    $("body").on("click", ".widget-navigation-collapse-tree .collapse-category .title", function()
    {
        const parent = $(this).closest(".collapse-category");
        const isActive = parent.hasClass("active");

        if (!isActive)
        {
            parent.addClass("active");
            parent.find(".content").slideDown("slow");
        }
        else
        {
            parent.removeClass("active");
            parent.find(".content").slideUp("slow");
        }
    });

});
