Vue.component("item-size-calc", {

    template: `<div class="item-size-calc" v-if="calculatorType">
        <div class="type-1" v-if="calculatorType == 1">
            <div class="input-row">
                <label for="calc-length">\${ currentVariation.variation.widthMM / 10 } cm Rollenbreite | Länge in cm</label>
                <input id="calc-length"type="text" :value="lengthValue" @change="setLengthValue($event.target.value)" />
            </div>
        </div>
        <div class="type-2" v-if="calculatorType == 2">
            <div class="input-row">
                <label for="calc-width">\${ currentVariation.variation.lengthMM / 10 } cm Rollenlänge | Breite in cm</label>
                <input id="calc-width" type="text" :value="widthValue" @change="setWidthValue($event.target.value)" />
            </div>
        </div>
        <div class="type-3" v-if="calculatorType == 3">
            <div class="input-row">
                <label for="calc-length">Länge in cm</label>
                <input id="calc-length"type="text" :value="lengthValue" @change="setLengthValue($event.target.value)" />
            </div>
            <div class="input-row">
                <label for="calc-width">Breite in cm</label>
                <input id="calc-width" type="text" :value="widthValue" @change="setWidthValue($event.target.value)" />
            </div>
        </div>
        <button class="btn btn-secondary mt-1" @click="submitCalc()">Preis neu berechnen</button>
    </div>`,

    inject: {
        itemId: {
            default: null
        }
    },

    props: {
        freeFields:
        {
            type: Object
        }
    },

    data()
    {
        return {
            lengthCmValue: 100,
            widthCmValue: 100
        };
    },

    created()
    {
        if ( this.calculatorType == 2 )
        {
            this.lengthCmValue = this.currentVariation.variation.lengthMM / 10;
        }
        if ( this.calculatorType == 1 )
        {
            this.widthCmValue = this.currentVariation.variation.widthMM / 10;
        }

        if ( this.calculatorType )
        {
            /* Override AddToBasket request params */
            $.ajaxPrefilter((options, originalOptions, jqXHR) =>
            {
                if (options.url.includes("/rest/io/basket/items") && options.method == "POST")
                {
                    if ( originalOptions.data.variationId == this.currentVariation.variation.id )
                    {
                        options.data += "&"+$.param({ inputLength: this.lengthCmValue, inputWidth: this.widthCmValue });
                    }
                }
            });
        }

        document.addEventListener("sizeCalcTrigger", data =>
        {
            this.submitCalc();
        });
        this.submitCalc();
    },

    computed:
    {
        currentVariation()
        {
            return this.$store.getters[`${this.itemId}/currentItemVariation`];
        },
        calculatorType()
        {
            let type = false;

            if (this.freeFields)
            {
                if (this.freeFields.free4 == "1" || this.freeFields.free4 == "2" || this.freeFields.free4 == "3")
                {
                    type = this.freeFields.free4;
                }
            }
            return type;
        },
        lengthValue()
        {
            return this.$options.filters.numberFormat(this.lengthCmValue);
        },
        widthValue()
        {
            return this.$options.filters.numberFormat(this.widthCmValue);
        }
    },

    methods:
    {
        setLengthValue(value)
        {
            if (typeof value === "string")
            {
                value = value.replace(App.decimalSeparator || ",", ".");
            }

            value = parseFloat(value);
            if (isNaN(value) || value < 1)
            {
                value = 100;
            }

            if (value > (this.currentVariation.variation.lengthMM / 10))
            {
                value = this.currentVariation.variation.lengthMM / 10;
            }

            this.lengthCmValue = value;

            this.submitCalc();
        },
        setWidthValue(value)
        {
            if (typeof value === "string")
            {
                value = value.replace(App.decimalSeparator || ",", ".");
            }

            value = parseFloat(value);
            if (isNaN(value) || value < 1)
            {
                value = 100;
            }

            if (value > (this.currentVariation.variation.widthMM / 10))
            {
                value = this.currentVariation.variation.widthMM / 10;
            }

            this.widthCmValue = value;

            this.submitCalc();
        },
        submitCalc()
        {
            if ( this.calculatorType )
            {
                document.dispatchEvent(new CustomEvent("sizeCalcUpdated", {
                    detail: {
                        lengthCmValue: this.lengthCmValue,
                        widthCmValue: this.widthCmValue
                    }
                }));
            }
        }
    }
});
