Vue.component("item-price", {

    props:
    {
        showCrossPrice:
        {
            type: Boolean,
            default: true
        }
    },

    data()
    {
        return {
            sizeMultiplier: 1
        };
    },

    inject: {
        itemId: {
            default: null
        }
    },

    created()
    {
        document.addEventListener("sizeCalcUpdated", data =>
        {
            if ( data.detail )
            {
                this.sizeMultiplier = (data.detail.lengthCmValue / 100) * (data.detail.widthCmValue / 100);
            }
        });

        document.dispatchEvent(new CustomEvent("sizeCalcTrigger", {}));
    },

    computed:
    {
        currentVariation()
        {
            return this.$store.getters[`${this.itemId}/currentItemVariation`];
        },

        hasCrossPrice()
        {
            return !!this.currentVariation.prices.rrp &&
                this.currentVariation.prices.rrp.unitPrice.value > 0 &&
                this.currentVariation.prices.rrp.unitPrice.value > this.currentVariation.prices.default.unitPrice.value;
        },

        variationGraduatedPrice()
        {
            return this.$store.getters[`${this.itemId}/variationGraduatedPrice`];
        },

        variationTotalPrice()
        {
            return this.sizeMultiplier * (this.$store.getters[`${this.itemId}/variationTotalPrice`] - this.$store.getters[`${this.itemId}/variationPropertySurcharge`]) + this.$store.getters[`${this.itemId}/variationPropertySurcharge`];
        },

        showDynamicPrice()
        {
            const state = this.$store.state.items[this.itemId];

            return App.config.item.showPleaseSelect && App.isCheapestSorting
                && (state.variationSelect && !state.variationSelect.isVariationSelected)
                && (state.pleaseSelectVariationId === this.currentVariation.variation.id
                    || state.pleaseSelectVariationId === 0);
        }
    }
});
